<template>
  <div v-loading="loading" class="lecture">
    <div class="event-detail-inner">
      <div class="event-detail-inner-inner">
        <div class="lecture-header">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/web/events' }"
              >寻根动态</el-breadcrumb-item
            >
            <el-breadcrumb-item>动态详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="lecture-detail">
          <div class="title">
            {{ detail.title || "三分钟教你认识名门修谱" }}
          </div>
          <img v-if="detail.image" :src="detail.image" alt="" class="banner">
          <div class="content" v-html="detail.content"></div>
          <div class="bottom">
            <div class="date">{{ detail.cdatetime || "2020-08-09" }}</div>
            <div class="view-count">浏览 {{ detail.view_count || "0" }} 次</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import otherApis from "@/apis/other";

export default {
  name: "LectureDetail",
  components: {
    "el-breadcrumb": Breadcrumb,
    "el-breadcrumb-item": BreadcrumbItem
  },
  data() {
    return {
      detail: {},
      loading: false
    };
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      this.loading = true;
      try {
        const {
          data: { article_info }
        } = await otherApis.articleDetail({
          id: this.$route.params.id
        });

        this.detail = article_info || {};
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scope>
.lecture {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  height: calc(100vh - 70px);

  .lecture-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;
    }
  }

  .banner {
    height: 300px;
    width: 100%;
    object-fit: contain;
    margin: 10px 0;
  }

  .event-detail-inner {
    width: 1200px;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
  }

  .event-detail-inner-inner {
    background: #f7f7f7;
    margin: 20px;
    // padding: 20px;
  }
}

.lecture-detail {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;

  .title {
    font-size: 24px;
    color: #333333;
  }

  .content {
    font-size: 14px;
    color: #666666;
    margin-top: 19px;
    line-height: 1.4;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #b5b5b5;
    font-size: 12px;
    margin-top: 18px;
  }
}
</style>
